<template>
  <div class="category bj">
    <div class="title">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="Refresh"
      ></el-button>

      <el-button type="primary" @click="addclick">新增</el-button>
      <el-button type="primary" @click="exportToExcel">导出</el-button>
      <!-- <div class="seach">
        <div class="inputs">
          <el-input v-model="from1.categories_name" placeholder="请输入品类名称" clearable></el-input>
        </div>

        <el-button @click="search" type="primary" icon="el-icon-search">查询</el-button>
      </div> -->
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column prop="id" align="center" label="ID">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.id) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="品类名称">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.name) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="is_audit_gao"
          align="center"
          label="高碑店集配发品是否需要审核"
        >
          <template slot-scope="scope">
            {{ scope.row.is_audit_gao == 1 ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="is_audit_xiao"
          align="center"
          label="孝感集配发品是否需要审核"
        >
          <template slot-scope="scope">
            {{ scope.row.is_audit_xiao == 1 ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="selectda(scope.$index, scope.row, 'parent')"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="selectda(scope.$index, scope.row, 'add')"
              >添加子类</el-button
            >
            <el-button
              type="text"
              @click="selectda(scope.$index, scope.row, 'children')"
              >查看子类</el-button
            >
            <el-button type="text" @click="seeOrigin(scope.row)"
              >产地
            </el-button>
            <!-- 选品平台需求去掉删除 -->
            <!-- <el-button type="text" @click="handleEdit(scope.$index, scope.row)"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 子类列表 -->
    <div class="enterprise personindex">
      <el-dialog
        :center="true"
        @close="tableVisible = false"
        :visible="tableVisible"
        title="查看子类"
        width="70%"
      >
        <div
          class="row-box d-flex flex-column mt-20"
          style="height: calc(100% - 64px)"
        >
          <el-table
            :data="listData"
            height="100%"
            :border="true"
            :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
            ref="multipleTable"
            style="width: 100%"
          >
            <el-table-column
              type="index"
              width="100px"
              align="center"
              label="序号"
            >
            </el-table-column>
            <el-table-column prop="id" width="100px" align="center" label="ID">
            </el-table-column>
            <el-table-column prop="name" align="center" label="名称">
              <template slot-scope="scope">
                {{ $empty.empty(scope.row.name) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="freight_parameter"
              align="center"
              label="运费系数(%)"
            >
              <template slot-scope="scope">
                {{ scope.row.freight_parameter }}
              </template>
            </el-table-column>
            <el-table-column
              prop="purchase_parameter"
              align="center"
              label="服务费系数(%)"
            >
              <template slot-scope="scope">
                {{ scope.row.purchase_parameter }}
              </template>
            </el-table-column>

            <!-- 20240624 二级品类标签 start -->
            <el-table-column
              label="品牌/品种"
              prop="tag"
              minWidth="100px"
              align="center"
            >
              <template slot-scope="scope">
                <div class="tag-wrap">
                  <div class="tag-box">
                    <span
                      v-for="child of scope.row.tags"
                      :key="child.id"
                      class="tag"
                      >{{ child.name }}</span
                    >
                  </div>
                  <span class="icon"
                    ><i
                      class="el-icon-edit-outline"
                      @click="onHandleTag(scope.row)"
                    ></i
                  ></span>
                </div>
              </template>
            </el-table-column>
            <!-- 20240624 二级品类标签 end -->

            <el-table-column
              label="排序"
              prop="listorder"
              width="100px"
              align="center"
            >
            </el-table-column>

            <el-table-column label="操作" align="center" min-width="130">
              <template slot-scope="scope">
                <el-button type="text" @click="editChild(scope.row)"
                  >编辑</el-button
                >
                <el-button type="text" @click="onHandleQrCode(scope.row)"
                  >小程序二维码</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <template #footer>
          <el-button @click="tableVisible = false">返回</el-button>
        </template>
      </el-dialog>
    </div>
    <!--
  二级品类 -->
    <el-dialog
      :title="childTitle"
      :visible.sync="childDialogVisible"
      v-if="childDialogVisible"
      width="70%"
      class="dialog"
      ref="refChildCategoryDialog"
      center
    >
      <div class="contents">
        <el-form
          :model="childForm"
          :rules="childRules"
          ref="childForm"
          label-width="200px"
          class="demo-ruleForm"
        >
          <el-form-item label="父级品类" prop="pid">
            <div>
              <el-select
                v-model="childForm.pid"
                clearable
                :disabled="childDisabled"
                placeholder="选择父级品类"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="品类名称" prop="name">
            <div>
              <el-input
                v-model="childForm.name"
                placeholder="请输入品类名称"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="运费系数(%)" prop="freight_parameter">
            <div class="inputs">
              <el-input
                v-model="childForm.freight_parameter"
                @blur="freightBlur"
                placeholder="请输入运费"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="服务费系数(%)" prop="purchase_parameter">
            <div class="inputs">
              <el-input
                v-model="childForm.purchase_parameter"
                @blur="purchaseBlur"
                placeholder="请输入服务费"
                clearable
              ></el-input>
            </div>
          </el-form-item>

          <!-- 二级品类增加排序 start -->
          <el-form-item label="排序" prop="listorder">
            <el-input
              v-model="childForm.listorder"
              placeholder="请输入排序值"
              maxlength="5"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="商品详情默认图片" prop="detail_pic">
            <b-file-upload
              key="detail_pic"
              :accepts="['jpg', 'jpeg', 'png']"
              :limit="1"
              v-model="detailPicList"
            ></b-file-upload>
          </el-form-item>

          <el-form-item label="商品详情默认视频" prop="detail_video">
            <b-file-upload
              key="detail_video"
              :accepts="['mp4', 'avi', 'flv', 'mov']"
              :limit="1"
              v-model="detailVideoList"
            ></b-file-upload>
          </el-form-item>
          <!-- 二级品类增加排序 end -->

          <!-- 禁售城市仓 start -->
          <el-form-item label="禁售城市仓" prop="logistic_id">
            <el-select
              class="logistic-select_wrap"
              placeholder="选择禁售城市仓"
              :loading="loadingCityStore"
              v-model="logisticIds"
              @change="onCityStore"
              multiple
              filterable
              clearable
            >
              <el-option
                v-for="item in cityStoreList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 禁售城市仓 end -->

          <el-form-item label="高碑店集配发品是否需要审核" prop="is_audit_gao">
            <el-radio-group
              v-model="childForm.is_audit_gao"
              :disabled="is_audit_gao == 1 ? true : false"
            >
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="孝感集配发品是否需要审核" prop="is_audit_xiao">
            <el-radio-group
              v-model="childForm.is_audit_xiao"
              :disabled="is_audit_xiao == 1 ? true : false"
            >
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="childDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="childConfirm">确认</el-button>
      </span>
    </el-dialog>
    <!--
  一级分类 -->
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      v-if="centerDialogVisible"
      width="70%"
      class="dialog"
      center
    >
      <div class="contents">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="160px"
          class="demo-ruleForm"
        >
          <el-form-item label="父级品类" required>
            <div v-if="title == '编辑品类'">
              <el-select
                v-model="ruleForm.id"
                clearable
                :disabled="parentDisabled"
                placeholder="选择父级品类"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div v-else>
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入品类名称"
                clearable
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item label="是否首页显示" prop="radius_pic">
            <div style="display: inline-block">
              <el-radio-group v-model="ruleForm.flag">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
              <div>
                <el-upload
                  action="#"
                  :on-preview="handlePictureCardPreview"
                  :class="{ hide: radius_pic.length == 1 }"
                  :file-list="radius_pic"
                  list-type="picture-card"
                  :http-request="
                    (file) => {
                      return uploadFile(file, 'radius_pic');
                    }
                  "
                  :before-remove="
                    (file, fileList) => {
                      return deleteFile(file, fileList, 'radius_pic');
                    }
                  "
                  :limit="1"
                >
                  <i slot="trigger" class="el-icon-plus"></i>
                </el-upload>
                <div class="text-center">显示图</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="高碑店集配发品是否需要审核" prop="is_audit_gao">
            <el-radio-group v-model="ruleForm.is_audit_gao">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="孝感集配发品是否需要审核" prop="is_audit_xiao">
            <el-radio-group v-model="ruleForm.is_audit_xiao">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="类型" prop="type">
            <el-select
              v-model="ruleForm.type"
              placeholder="请选择类型"
              style="width: 200px"
            >
              <el-option label="果蔬" :value="1"></el-option>
              <el-option label="非果蔬" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div v-show="showImage" class="shadow" @click="showImage = false"></div>
      <div v-show="showImage" class="bigImage">
        <img :src="bigImage" alt="" />
        <i
          class="el-icon el-icon-close bigClose"
          @click="showImage = false"
        ></i>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCategories">确认</el-button>
      </span>
    </el-dialog>

    <!-- 当前列数据生成的二维码 start -->
    <qr-code-dialog ref="refQrCode"></qr-code-dialog>
    <!-- 当前列数据生成的二维码 end -->

    <!-- 子类标签，弹窗 start -->
    <goods-tag-dialog
      ref="refGoodsTagDialog"
      @on-change="ajaxGetCategories"
    ></goods-tag-dialog>
    <!-- 子类标签，弹窗 end -->
  </div>
</template>
<script>
import tool from "@/utils/tools/tool";
import throttle from "lodash/throttle";
import {
  getCategoriesList,
  postAddCategories,
  postUpdateCategories,
  postDeleteCategories,
  getCategories,
} from "@/api/generalControl/category";
import QrCodeDialog from "@/components/common/QrCodeDialog/index.vue";
import { MEMBER_QR_CODE_CONFIG, MINI_APP_PR_CODE_PAGE } from "@/utils/enum";
import { getMiniAppQrCode } from "@/api/export";
import { downLoadFlowBlob } from "@/utils/tools/base";
import { validInteger } from "@/utils/tools/validate";
import BFileUpload from "@/components/business/BFileUpload/index.vue";
import GoodsTagDialog from "./modules/goods-tag-dialog/index.vue";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
export default {
  name: "category",
  components: {
    QrCodeDialog,
    BFileUpload,
    GoodsTagDialog,
  },
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      loadingCityStore: false, // 城市仓加载loading
      cityStoreList: [], // 城市仓列表
      detailPicList: [], // 图片列表
      detailVideoList: [], // 视频列表
      from: {
        page: 1,
        pageSize: 10,
      },
      from1: {
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      listData: [],
      parentDisabled: false,
      total: 0,
      cateName: "",
      //列表页面不同功能参数
      ruleForm: {
        id: "",
        name: "",
        // kv: "",
        pic: "",
        radius_pic: "",
        flag: "1",
        is_audit_xiao: "", //	number	孝感是否审核
        is_audit_gao: "", //	number	高碑店是否审核
        type: "", //	number	类型 1果蔬 2非果蔬 一级品类传输
      },
      childForm: {
        pid: "",
        id: "",
        name: "",
        freight_parameter: 100,
        purchase_parameter: 100,
        listorder: "", // 排序值
        detail_pic: "", // 商品详情默认图片
        detail_video: "", // 商品详情默认视频
        logistic_id: "", // 禁售城市仓
        is_audit_xiao: "", //	number	孝感是否审核
        is_audit_gao: "", //	number	高碑店是否审核
      },
      is_audit_xiao: "", //	number	孝感是否审核用来判断子集是否能改
      is_audit_gao: "", //	number	高碑店是否审核用来判断子集是否能改
      logisticIds: [], // 禁售城市仓，选中集合
      tagList: [
        {
          id: 1,
          name: "水萝卜",
        },
        {
          id: 2,
          name: "西贡果",
        },
        {
          id: 3,
          name: "果多美",
        },
      ], // 二级品类下的标签
      parentId: "",
      title: "",
      childTitle: "",
      centerDialogVisible: false,
      childDialogVisible: false,
      tableVisible: false,
      rules: {
        // kv: [{ required: true, message: "请上传图片", trigger: "blur" }],
        radius_pic: [
          { required: true, message: "请上传显示图", trigger: "change" },
        ],
        is_audit_xiao: [
          { required: true, message: "请选择孝感是否审核", trigger: "change" },
        ],
        is_audit_gao: [
          {
            required: true,
            message: "请选择高碑店是否审核",
            trigger: "change",
          },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
      },
      childRules: {
        name: [{ required: true, message: "请输入品类名称", trigger: "blur" }],
        freight_parameter: [
          {
            type: "number",
            min: 0,
            message: "最小必须是0",
            trigger: "blur",
          },
        ],
        purchase_parameter: [
          {
            type: "number",
            min: 0,
            message: "最小必须是0",
            trigger: "blur",
          },
        ],
        purchase_parameter: [
          {
            type: "number",
            max: 100,
            message: "请输入0到100的数字",
            trigger: "blur",
          },
        ],
        is_audit_xiao: [
          { required: true, message: "请选择孝感是否审核", trigger: "change" },
        ],
        is_audit_gao: [
          {
            required: true,
            message: "请选择高碑店是否审核",
            trigger: "change",
          },
        ],
        listorder: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (value !== 0 && !value) {
                callback(new Error("请输入排序值！"));
              }
              if (validInteger(value)) {
                callback(new Error("请输入大于等于0的整数！"));
              }
              callback();
            },
          },
        ],
        // detail_pic: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     validator: (rule, value, callback) => {
        //       if (!this.detailPicList.length) {
        //         callback(new Error("请上传图片！"));
        //       }
        //       callback();
        //     },
        //   },
        // ],
        // detail_video: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     validator: (rule, value, callback) => {
        //       if (!this.detailVideoList.length) {
        //         callback(new Error("请上传视频！"));
        //       }
        //       callback();
        //     },
        //   },
        // ],
        // logistic_id: [
        //   {
        //     required: true,
        //     trigger: ["blur", "change"],
        //     message: "请选择禁售城市仓",
        //   },
        // ],
      },
      kv: [],
      pic: [],
      radius_pic: [],
      bigImage: "",
      showImage: false,
      childDisabled: false,
      options: [],
    };
  },
  watch: {
    /**
     * 图片发生改变
     */
    detailPicList() {
      const res = this.detailPicList?.map((item) => item.url).join(",");
      this.childForm.detail_pic = res;
    },
    /**
     * 视频发生改变
     */
    detailVideoList() {
      const res = this.detailVideoList?.map((item) => item.url).join(",");
      this.childForm.detail_video = res;
    },
  },
  created() {
    this.getAjaxCategoriesList();
  },
  mounted() {
    this.ajaxGetCityStoreList();
  },
  methods: {
    // 查看产地
    seeOrigin(row) {
      this.$router.push({
        path: `/origin`,
        query: {
          id: row.id,
          name: row.name,
        },
      });
    },
    exportToExcel() {
      // 假设你的数据是从某处来, 比如一个表格的数组
      const data = [["一级品类", "二级品类", "三级品类"]];
      this.tableData.forEach((item) => {
        if (item.child.length > 0) {
          item.child.forEach((item1) => {
            if (item1.tags.length > 0) {
              let tagsname = item1.tags.map((item2) => item2.name).join(",");

              // item1.tags.forEach((item2) => {
              data.push([item.name, item1.name, tagsname]);
              // });
            } else {
              data.push([item.name, item1.name, ""]);
            }
          });
        } else {
          data.push([item.name, "", ""]);
        }
      });

      // 将数据转换为工作表
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      // 创建工作簿并添加工作表
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // 生成Excel文件
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // 使用blob和FileReader来处理二进制数据
      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(dataBlob, "品类.xlsx");
    },
    /**
     * 获取城市仓列表
     */
    async ajaxGetCityStoreList() {
      this.loadingCityStore = true;
      const params = {
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        this.cityStoreList = data.data;
        console.log("ajx ajaxGetCityStoreList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      } finally {
        this.loadingCityStore = false;
      }
    },
    /**
     * 城市仓变动
     */
    onCityStore() {
      this.childForm.logistic_id = this.logisticIds.join(",");
    },
    /**
     * 上传图片
     */
    onHandleUploadFile(options, name) {
      if (name === "detailPicList") {
        this.loadingUploadPic = true;
      } else {
        this.loadingUploadVideo = true;
      }
      // 拿到 file
      let file = options.file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        // console.log(e.target.result);
        this.ajaxUploadImage(e.target.result, name, options.file.uid);
      };
    },
    /**
     * 删除图片
     */
    onHandleDeleteFile(name) {
      this[name] = [];
      if (name === "detailPicList") {
        this.childForm.detail_pic = "";
        this.$refs.refChildCategoryDialog.validateField("detail_pic");
      } else {
        this.childForm.detail_video = "";
        this.$refs.refChildCategoryDialog.validateField("detail_video");
      }
    },
    /**
     * 图片路径请求
     */
    async ajaxUploadImage(img, name, uid) {
      try {
        const res = await this.$api.general.uploadImage({ file_data: img });
        // 增加uid 赋值 解决上传图片跳动问题
        this[name] = [{ url: res.data, uid }];
        if (name === "detailPicList") {
          this.childForm.detail_pic = res.data;
          this.$refs.refChildCategoryDialog.validateField("detail_pic");
        } else {
          this.childForm.detail_video = res.data;
          this.$refs.refChildCategoryDialog.validateField("detail_video");
        }
      } catch (err) {
      } finally {
        if (name === "detailPicList") {
          this.loadingUploadPic = false;
        } else {
          this.loadingUploadVideo = false;
        }
      }
    },
    /**
     * 预览
     */
    handlePreview(file, name) {
      this.bigImage = file.url;
      this.showImage = true;
    },

    /**
     * 校验运费系数
     */
    freightBlur() {
      console.log(tool);
      this.childForm.freight_parameter = Number(
        tool.filterUnNumber(this.childForm.freight_parameter)
      );
    },
    /**
     * 校验服务费系数
     */
    purchaseBlur() {
      this.childForm.purchase_parameter = Number(
        tool.filterUnNumber(this.childForm.purchase_parameter)
      );
    },
    /**
     * 搜索列表
     */
    search() {
      this.from1.page = 1;
      this.currentPage = 1;
      this.from = this.from1;
      this.getAjaxCategoriesList();
    },
    /**
     * 点击新增按钮
     */
    addclick() {
      this.parentDisabled = false;
      for (let key in this.ruleForm) {
        switch (key) {
          case "flag":
            this.ruleForm[key] = "1";
            break;
          default:
            this.ruleForm[key] = "";
        }
      }
      this.kv = [];
      this.pic = [];
      this.radius_pic = [];
      this.centerDialogVisible = true;
      this.title = "新增品类";
    },
    /**
     * 新增/编辑一级品类
     */
    addCategories: throttle(function () {
      console.log(this.ruleForm);
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.title == "新增品类") {
            if (this.ruleForm.name) {
              await postAddCategories(this.ruleForm);
              this.$message({
                type: "success",
                message: "添加成功",
              });
              this.centerDialogVisible = false;
              this.Refresh();
            } else {
              this.tool.message("请输入父级分类", "error");
            }
          } else {
            // let params = {
            //   id: this.ruleForm.id,
            //   name: this.ruleForm.name,
            //   kv: this.ruleForm.kv,
            //   pic: this.ruleForm.pic,
            //   radius_pic: this.ruleForm.radius_pic,
            //   flag: this.ruleForm.flag,
            // };
            try {
              await postUpdateCategories(this.ruleForm);
              this.centerDialogVisible = false;
              this.getAjaxCategoriesList();
            } catch (err) {
              console.log("ajax postUpdateCategories err", err);
            }
          }
        }
      });
    }, 500),
    getGlass(src) {
      this.bigImage = src;
      this.showImage = true;
    },
    uploadFile(options, name) {
      // 拿到 file
      let file = options.file;
      let date = new Date().getTime();
      console.log(file, "文件");
      var event = event || window.event;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.uploadImage(e.target.result, name);
      };
    },
    uploadImage(img, name) {
      this.$api.general.uploadImage({ file_data: img }).then((res) => {
        this[name] = [{ url: res.data }];
        this.ruleForm[name] = res.data;
      });
    },
    handlePictureCardPreview(file) {
      this.bigImage = file.url;
      this.showImage = true;
    },
    deleteFile(file, fileList, name) {
      this[name] = fileList;
      this.ruleForm[name] = fileList;
    },
    childConfirm: throttle(function () {
      this.$refs.childForm.validate(async (valid) => {
        if (valid) {
          /** 参数重组 */
          const {
            pid,
            name,
            freight_parameter,
            purchase_parameter,
            listorder,
            detail_pic,
            detail_video,
            logistic_id,
            is_audit_gao,
            is_audit_xiao,
          } = this.childForm;
          const params = {
            pid,
            name,
            freight_parameter,
            purchase_parameter,
            listorder: Number(listorder),
            detail_pic,
            detail_video,
            logistic_id,
            is_audit_gao,
            is_audit_xiao,
          };
          if (this.childTitle == "编辑子类") {
            params.id = this.childForm.id;
            try {
              await postUpdateCategories(params);
              this.childDialogVisible = false;
              let { data } = await getCategories({
                id: this.parentId,
              });
              this.$message({
                type: "success",
                message: "编辑成功",
              });
              this.listData = data.child;
            } catch (err) {
              console.log("ajax postUpdateCategories err", err);
            }
          } else {
            try {
              await postAddCategories(params);
              this.$message({
                type: "success",
                message: "添加成功",
              });
              this.childDialogVisible = false;
              this.tableVisible = false;
              this.getAjaxCategoriesList();
            } catch (err) {
              console.log("ajax postAddCategories err", err);
            }
          }
        }
      });
    }, 500),
    Refresh() {
      this.from = {
        page: 1,
        pageSize: 10,
      };
      this.from1 = {
        page: 1,
        pageSize: 10,
      };
      this.currentPage = 1;
      this.getAjaxCategoriesList();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.getAjaxCategoriesList();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.getAjaxCategoriesList();
    },

    async selectda(index, row, word) {
      if (word == "parent") {
        this.title = "编辑品类";
        this.parentDisabled = true;
        for (let key in this.ruleForm) {
          this.ruleForm[key] = row[key];
        }
        console.log(this.ruleForm);
        this.kv = row.kv ? [{ url: row.kv }] : [];
        this.pic = row.pic ? [{ url: row.pic }] : [];
        this.radius_pic = row.radius_pic ? [{ url: row.radius_pic }] : [];
        this.centerDialogVisible = true;
      } else if (word == "children") {
        this.$router.push({
          path: "/categoryChildren",
          query: {
            id: row.id,
          },
        });
        // this.tableVisible = true;
        // try {
        //   this.parentId = row.id;
        //   let { data } = await getCategories({ id: this.parentId });
        //   this.listData = data.child;
        // } catch (error) {
        //   console.log("ajax getCategories err", err);
        // }
      } else {
        this.childTitle = "新增子类";
        this.is_audit_xiao = row.is_audit_xiao; //	number	孝感是否审核用来判断子集是否能改
        this.is_audit_gao = row.is_audit_gao; //	number	高碑店是否审核用来判断子集是否能改
        this.childForm = {
          pid: row.id,
          id: "",
          name: "",
          freight_parameter: 100,
          purchase_parameter: 100,
          listorder: "",
          detail_pic: "",
          detail_video: "",
          logistic_id: "",
          is_audit_xiao: row.is_audit_xiao,
          is_audit_gao: row.is_audit_gao,
        };
        /** 重置数据 */
        this.onInitSelectData();
        this.childDisabled = true;
        this.childDialogVisible = true;
      }
    },
    /**
     * 初始化一些数据
     */
    onInitSelectData() {
      this.detailPicList = [];
      this.detailVideoList = [];
      this.logisticIds = [];
    },
    /**
     * 编辑二级品类
     */
    editChild(row) {
      this.onInitSelectData();
      this.childTitle = "编辑子类";
      this.childDisabled = true;
      for (let key in this.childForm) {
        this.childForm[key] = row[key];
      }
      /** 回显信息处理 */
      if (this.childForm?.logistic_id) {
        this.logisticIds = this.childForm?.logistic_id
          .split(",")
          .map((child) => Number(child));
      }
      if (this.childForm.detail_pic) {
        this.detailPicList = this.childForm.detail_pic
          .split(",")
          .map((child) => {
            return {
              name: child,
              url: child,
            };
          });
      }
      if (this.childForm.detail_video) {
        this.detailVideoList = [
          {
            name: this.childForm.detail_video,
            url: this.childForm.detail_video,
          },
        ];
      }
      // this.childForm.id = row.id;
      // this.childForm.pid = row.pid;
      // this.childForm.name = row.name;
      // this.childForm.purchase_parameter = row.purchase_parameter;
      // this.childForm.freight_parameter = row.freight_parameter;
      this.childDialogVisible = true;
    },
    /**
     * 获取品类数据
     */
    async getAjaxCategoriesList() {
      this.loading = true;
      try {
        let { data } = await getCategoriesList(this.from);
        this.tableData = data;
        this.options = data;
        this.loading = false;
      } catch (err) {
        console.log("ajax getCategoriesList err", err);
      }
    },
    /**
     * 展示二维码
     */
    async onHandleQrCode(row) {
      const { id, pid, name } = row;
      const { cate } = MEMBER_QR_CODE_CONFIG[process.env.NODE_ENV];
      const params = {
        env_version: cate.envCode,
        type: MINI_APP_PR_CODE_PAGE.cate.value,
        firstId: pid,
        secondId: id,
      };
      try {
        const res = await getMiniAppQrCode(params);
        console.log("ajax getMiniAppQrCode", res);
        downLoadFlowBlob(res, res?.filename || name);
      } catch (err) {
        console.log("ajax getMiniAppQrCode err", err);
      }
      /** 前端生成二维码逻辑，已弃 */
      // const { cate } = MEMBER_QR_CODE_CONFIG[process.env.NODE_ENV];
      // const url = cate?.value && `${cate.value}?firstId=${pid}&secondId=${id}`;
      // const params = {
      //   url,
      //   name,
      // };
      // this.$refs.refQrCode.onInitData(params);
      // console.log("🆒 onHandleQrCode", row, params);
    },
    /**
     * 编辑二级品类 tag
     */
    onHandleTag(row) {
      // this.tagList
      const tags = row.tags;
      const tagList = tags.map((child) => {
        return {
          id: child.id,
          name: child.name,
        };
      });
      const params = {
        cid: row.id,
        is_show: tags?.[0]?.is_show,
        tag_list: tagList || [],
      };
      this.$refs.refGoodsTagDialog.onInitData(params);
    },
    /**
     * 获取二级品类列表
     */
    async ajaxGetCategories() {
      const params = {
        id: this.parentId,
      };
      try {
        const { data } = await getCategories(params);
        this.listData = data.child;
      } catch (err) {
        console.log("ajax getCategories err", err);
      }
    },
  },
};
</script>
<style lang="scss">
.category {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;
  .hide {
    .el-upload--picture-card {
      display: none;
    }
  }
  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;
    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }
    .imgs {
      display: flex;
      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;
        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }
        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }
    .disply {
      display: flex;
      margin-bottom: 22px;
    }
    .mr {
      margin-right: 31px;
    }
    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }
      .el-input {
        width: 302px;
      }
    }
  }
}
.colors {
  color: #fa884c;
}
.times {
  margin-right: 8px;
}
// .el-picker-panel {
//   left: 1245px !important;
// }

.logistic-select_wrap {
  // height: 36px;
  // .el-input {
  //   height: 100%;
  // }
}
/** 标签样式 */
$tag-color: #545454;
$color: #13ae67;
.tag-wrap {
  display: flex;
  justify-content: center;
  //   gap: 5px;
  .tag-box {
    // max-width: 200px;
    // height: 35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    // overflow: hidden;
  }
  .tag {
    // height: 100%;
    line-height: 26px;
    padding: 3px 5px;
    border: 0.5px solid rgba($tag-color, 0.3);
    box-sizing: border-box;
    border-radius: 5px;
    color: $tag-color;
    background-color: rgba($tag-color, 0.1);
  }
  .icon {
    padding: 5px;
    border-radius: 5px;
    color: $color;
    cursor: pointer;
  }
}
</style>
